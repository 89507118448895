var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.isMobile ? _c('div', [_c('AddressDocumentDetailedMobile', {
    attrs: {
      "lfdnr": _vm.lfdnr,
      "wf_cid": _vm.wf_cid
    }
  })], 1) : _c('div', [_c('AddressDocumentDetailed', {
    attrs: {
      "lfdnr": _vm.lfdnr,
      "wf_cid": _vm.wf_cid
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };