import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('LBADialog', {
    on: {
      "close": function ($event) {
        _vm.showDocumentTree = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Abhängigkeiten ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VTreeview, {
          attrs: {
            "items": _vm.document.documentTree,
            "hoverable": "",
            "dense": "",
            "open-all": ""
          },
          scopedSlots: _vm._u([{
            key: "prepend",
            fn: function ({
              item
            }) {
              return [item ? _c(VIcon, [_vm._v(" mdi-file-document-outline ")]) : _vm._e()];
            }
          }, {
            key: "label",
            fn: function ({
              item
            }) {
              return [_vm.document.wf_cid != item.wfcid ? _c('router-link', {
                attrs: {
                  "to": {
                    name: 'crmAddressDocumentDetailed',
                    params: {
                      lfdnr: item.lfdnr,
                      wf_cid: item.wfcid
                    }
                  }
                }
              }, [_vm._v(_vm._s(item.name))]) : _c('span', [_vm._v(_vm._s(item.name))])];
            }
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showDocumentTree,
      callback: function ($$v) {
        _vm.showDocumentTree = $$v;
      },
      expression: "showDocumentTree"
    }
  }), _c('LBADialog', {
    on: {
      "close": function ($event) {
        _vm.showInteractions = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Interaktionen")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VTextField, {
          staticClass: "mt-2",
          attrs: {
            "label": "Interaktionen durchsuchen",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-inner-icon": "mdi-magnify",
            "clear-icon": "mdi-close-circle-outline"
          },
          model: {
            value: _vm.interactionSearch,
            callback: function ($$v) {
              _vm.interactionSearch = $$v;
            },
            expression: "interactionSearch"
          }
        }), _c(VDataTable, {
          staticClass: "elevation-0 overflow-y-auto",
          staticStyle: {
            "height": "25vh"
          },
          attrs: {
            "mobile-breakpoint": 0,
            "headers": _vm.interactionHeaders,
            "items": _vm.document.interactions,
            "search": _vm.interactionSearch,
            "loading": _vm.loading || _vm.loadingDocument,
            "loading-text": "Daten werden geladen...",
            "hide-default-footer": "",
            "disable-sort": "",
            "item-key": "date",
            "dense": "",
            "expanded": _vm.interactionTableExpandedSync
          },
          on: {
            "update:expanded": function ($event) {
              _vm.interactionTableExpandedSync = $event;
            },
            "click:row": _vm.clickRow
          },
          scopedSlots: _vm._u([{
            key: "loading",
            fn: function () {
              return [_c(VSkeletonLoader, {
                attrs: {
                  "type": "table-row@5"
                }
              })];
            },
            proxy: true
          }, {
            key: "item.date",
            fn: function ({
              item
            }) {
              return [_c('span', [_vm._v(_vm._s(_vm.formatDate(item.date)))])];
            }
          }, {
            key: "expanded-item",
            fn: function ({
              headers,
              item
            }) {
              return [_c('td', {
                staticClass: "py-4",
                attrs: {
                  "colspan": headers.length
                }
              }, [_c(VSimpleTable, {
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function () {
                    return [_c('tbody', [_c('tr', [_c('td', [_vm._v("Erstelldatum")]), _c('td', [_vm._v(_vm._s(_vm.formatDate(item.aDat)))])]), _c('tr', [_c('td', [_vm._v("Zuletzt verändert")]), _c('td', [_vm._v(_vm._s(_vm.formatDate(item.mDat)))])]), _c('tr', [_c('td', [_vm._v("Betreff Code")]), _c('td', [_vm._v(_vm._s(item.subject.code))])]), _c('tr', [_c('td', [_vm._v("Betreff")]), _c('td', [_vm._v(_vm._s(item.subject.description))])]), _c('tr', [_c('td', [_vm._v("Erfassung")]), _c('td', [_vm._v(_vm._s(item.capture))])]), _c('tr', [_c('td', [_vm._v("Stichworte")]), _c('td', [_vm._v(_vm._s(item.keywords))])]), _c('tr', [_c('td', [_vm._v("Text")]), _c('td', {
                      domProps: {
                        "innerHTML": _vm._s(item.text)
                      }
                    })])])];
                  },
                  proxy: true
                }], null, true)
              })], 1)];
            }
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showInteractions,
      callback: function ($$v) {
        _vm.showInteractions = $$v;
      },
      expression: "showInteractions"
    }
  }), _vm.documentExists ? _c(VContainer, [_c(VRow, [_c(VCol, [_vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "image"
    }
  })] : _c(VCard, {
    staticClass: "elevation-0 pa-y px-4 rounded-0 border-left-primary-3",
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, [_c('router-link', {
    attrs: {
      "to": {
        name: 'crmAddress',
        params: {
          lfdnr: _vm.address.key.lfdnr
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.address.firstname == null && _vm.address.lastname == null ? _vm.address.name : `${_vm.address.firstname} ${_vm.address.lastname}`) + " ")])], 1), _c(VCardSubtitle, [_vm._v(" " + _vm._s(_vm.document.name) + " (" + _vm._s(_vm.document.state) + ") ")]), _c(VCardText, {
    staticClass: "pb-6"
  }, [_c(VRow, {
    staticClass: "mx-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" Straße: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.address.address.street) + " ")])], 1), _c(VRow, {
    staticClass: "mx-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" Ort: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.address.address.zip) + " " + _vm._s(_vm.address.address.city) + " ")])], 1), _c(VRow, {
    staticClass: "mx-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" Land: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm.address.address.country.lfdnr == 1 ? _c('span', [_vm._v(_vm._s('Austria') + " ")]) : _vm.address.address.country.lfdnr == 15 ? _c('span', [_vm._v(_vm._s('Germany') + " ")]) : _vm._e()])], 1)], 1), _c(VCardActions)], 1)], 2)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    staticClass: "ml-2",
    attrs: {
      "type": "button"
    }
  })] : _c('LBAButton', {
    on: {
      "click": function ($event) {
        _vm.showDocumentTree = !_vm.showDocumentTree;
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-file-tree")]), _vm._v(" Abhängigkeiten")], 1)], 2), _c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    staticClass: "ml-2",
    attrs: {
      "type": "button"
    }
  })] : _c('LBAButton', {
    on: {
      "click": function ($event) {
        _vm.showInteractions = true;
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-file-document-outline")]), _vm._v(" Interaktionen")], 1)], 2)], 1), _c(VRow, [_c(VCol, [_vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "text@6"
    }
  })] : _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.document.header)
    }
  })], 2)], 1), _c(VRow, [_c(VCol, [_c('h2', [_vm._v("Positionen")])])], 1), _c(VRow, [_c(VCol, [_c(VContainer, [_c(VTextField, {
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "label": "Suche",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c(VRow, [_c(VCol, [_c(VDataTable, {
    staticClass: "elevation-0",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.document.positions,
      "search": _vm.search,
      "loading": _vm.loading || _vm.loadingDocument,
      "loading-text": "Daten werden geladen...",
      "show-expand": "",
      "item-key": "id.pos",
      "mobile-breakpoint": 0,
      "single-expand": _vm.positionTableSingleExpand,
      "expanded": _vm.positionTableExpandedSync
    },
    on: {
      "update:expanded": function ($event) {
        _vm.positionTableExpandedSync = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c(VSkeletonLoader, {
          attrs: {
            "type": "table-row@5"
          }
        })];
      },
      proxy: true
    }, {
      key: "expanded-item",
      fn: function ({
        headers,
        item
      }) {
        return [_c('td', {
          staticClass: "py-4",
          attrs: {
            "colspan": headers.length
          }
        }, [_c(VSimpleTable, {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('td', [_vm._v("Position")]), _c('td', [_vm._v(_vm._s(item.id.pos))])]), _c('tr', [_c('td', [_vm._v("Artikel Nr.")]), _c('td', [_vm._v(_vm._s(item.artNr))])]), _c('tr', [_c('td', [_vm._v("Artikel")]), _c('td', [_vm._v(_vm._s(item.article))])]), _c('tr', [_c('td', [_vm._v("SM Code")]), _c('td', [_vm._v(_vm._s(item.smCode))])]), _c('tr', [_c('td', [_vm._v("Sachmerkmal")]), _c('td', [_vm._v(_vm._s(item.sm))])]), _c('tr', [_c('td', [_vm._v("Menge")]), _c('td', [_vm._v(_vm._s(item.quantity))])]), _c('tr', [_c('td', [_vm._v("Einheit")]), _c('td', [_vm._v(_vm._s(item.unit))])]), _c('tr', [_c('td', [_vm._v("Nettopreis")]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(item.singlePriceNet)))])]), _c('tr', [_c('td', [_vm._v("Bruttopreis")]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(item.singlePriceGross)))])]), _c('tr', [_c('td', [_vm._v("Rabatt 1")]), _c('td', [_vm._v(_vm._s(_vm.formatPercentage(item.discount1)))])]), _c('tr', [_c('td', [_vm._v("Rabatt 2")]), _c('td', [_vm._v(_vm._s(_vm.formatPercentage(item.discount2)))])]), _c('tr', [_c('td', [_vm._v("Steuersatz")]), _c('td', [_vm._v(_vm._s(item.tax))])]), _c('tr', [_c('td', [_vm._v("Gesamtpreis Netto")]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(item.totalPriceNet)))])]), _c('tr', [_c('td', [_vm._v("Gesamtpreis Brutto")]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(item.totalPriceGross)))])]), _c('tr', [_c('td', [_vm._v("Text")]), item.posText != '' ? _c('td', {
                domProps: {
                  "innerHTML": _vm._s(item.posText)
                }
              }) : _c('td', [_vm._v("Kein Positionstext vorhanden")])])])];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }], null, false, 3158778743)
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VIcon, [_vm._v("mdi-truck-alert")]), _vm._v(" Lieferbedingung "), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "table-cell"
    }
  })] : _c(VTextField, {
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.document.deliveryCondition,
      callback: function ($$v) {
        _vm.$set(_vm.document, "deliveryCondition", $$v);
      },
      expression: "document.deliveryCondition"
    }
  }), _c(VIcon, [_vm._v("mdi-cash")]), _vm._v(" Zahlungsbedingung "), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "table-cell"
    }
  })] : _c(VTextField, {
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.document.paymentCondition,
      callback: function ($$v) {
        _vm.$set(_vm.document, "paymentCondition", $$v);
      },
      expression: "document.paymentCondition"
    }
  }), _c(VIcon, [_vm._v("mdi-package-variant-closed")]), _vm._v(" Versandart "), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "table-cell"
    }
  })] : _c(VTextField, {
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.document.deliveryType,
      callback: function ($$v) {
        _vm.$set(_vm.document, "deliveryType", $$v);
      },
      expression: "document.deliveryType"
    }
  }), _c(VIcon, [_vm._v("mdi-cash")]), _vm._v(" Zahlungsart "), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "table-cell"
    }
  })] : _c(VTextField, {
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.document.paymentType,
      callback: function ($$v) {
        _vm.$set(_vm.document, "paymentType", $$v);
      },
      expression: "document.paymentType"
    }
  })], 2)], 1), _c(VSpacer), _c(VRow, {
    staticClass: "mt-4",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c('b', [_vm._v("Warenwert Netto: ")])]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.totalPriceNet)))])])], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v("Umsatzsteuer: ")]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.tax)))])], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c('b', [_vm._v("Warenwert Brutto: ")])]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.totalPriceGross)))])])], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v("Kopfrabatt: ")]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.formatPercentage(_vm.document.discount)))])], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    staticClass: "red--text",
    attrs: {
      "cols": "8"
    }
  }), _c(VCol, {
    staticClass: "text-right red--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.discountValue)))])], 1), _c(VDivider, {
    staticClass: "my-3"
  }), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c('b', [_vm._v("Zu zahlender Betrag: ")])]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.totalPriceToPay)))])])], 1), _c(VBottomNavigation, {
    staticClass: "pa-3",
    attrs: {
      "fixed": "",
      "horizontal": "",
      "scroll-threshold": "500",
      "hide-on-scroll": ""
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c('b', [_vm._v("Zu zahlender Betrag: ")])]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.totalPriceToPay)))])])], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };