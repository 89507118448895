import AddressDocumentDetailed from './AddressDocumentDetailed.vue';
import AddressDocumentDetailedMobile from './AddressDocumentDetailedMobile.vue';
export default {
  components: {
    AddressDocumentDetailed,
    AddressDocumentDetailedMobile
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: true
    }
  },
  computed: {
    isMobile() {
      var breakpoint = this.$vuetify.breakpoint.name;
      console.log(['xs', 'sm'].includes(breakpoint));
      if (['xs', 'sm'].includes(breakpoint)) return true;
      console.log('here');
      return false;
    }
  }
};