import "core-js/modules/es.array.push.js";
import axios from 'axios';
//import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
//import InteractionsTable from '@/components/crm/addresses/InteractionsTable.vue'
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import documentService from '@/services/crm/documentService.js';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import addressService from '@/services/crm/addressService.js';
import dateTimeService from '@/services/cal/dateTimeService.js';

//https://diegoazh.github.io/gmap-vue/

export default {
  components: {
    //SiteHeaderComponent,
    //InteractionsTable,
    ErrorDialog
  },
  data: () => ({
    address: {
      key: {
        lfdnr: '',
        firma: ''
      },
      address: {
        street: '',
        zip: '',
        country: {
          firma: '',
          sprache: '',
          lfdnr: ''
        }
      }
    },
    document: {
      name: '',
      nr: '',
      date: '',
      type: '',
      shortType: '',
      state: '',
      wf_cid: '',
      printLoading: false
    },
    fullscreen: false,
    search: '',
    open: [0],
    interactionSearch: null,
    positionTableSingleExpand: true,
    positionTableExpandedSync: [],
    interactionTableSingleExpand: true,
    interactionTableExpandedSync: [],
    loading: true,
    loadingDocument: true,
    upgradeDialog: false,
    possibleUpgrades: [],
    fromUpgade: '',
    toUpgrade: '',
    SuccessSnackbar: false,
    upgradedDocument: {},
    documentExists: true,
    caseSensitive: false,
    headers: [{
      text: 'Pos',
      value: 'id.pos'
    }, {
      text: 'Artikel Nr.',
      value: 'artNr'
    }, {
      text: 'Artikel',
      value: 'article'
    }, {
      text: 'SM Code',
      value: 'smCode'
    }, {
      text: 'SM',
      value: 'sm'
    }, {
      text: 'Menge',
      value: 'quantity',
      align: 'end'
    }, {
      text: 'EH',
      value: 'unit'
    }, {
      text: 'Nettopreis',
      value: 'singlePriceNet',
      align: 'end'
    }, {
      text: 'Bruttopreis',
      value: 'singlePriceGross',
      align: 'end'
    }, {
      text: 'Rabatt 1',
      value: 'discount1',
      align: 'end'
    }, {
      text: 'Rabatt 2',
      value: 'discount2',
      align: 'end'
    }, {
      text: 'Steuersatz',
      value: 'tax'
    }, {
      text: 'Gesamtpreis Netto',
      value: 'totalPriceNet',
      align: 'end'
    }, {
      text: 'Gesamtpreis Brutto',
      value: 'totalPriceGross',
      align: 'end'
    }],
    interactionHeaders: [{
      text: 'Datum',
      value: 'date'
    }, {
      text: 'Betreff',
      value: 'subject.description'
    }, {
      text: 'Stichworte',
      value: 'keywords'
    }],
    failMessage: '',
    errorDialog: false
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: true
    }
  },
  computed: {
    siteTitle() {
      return this.document.name;
    },
    fullscreenStyle() {
      return !this.fullscreen ? 'max-width:75%' : '';
    }
  },
  methods: {
    init() {
      this.documentExists = true;
      this.$store.dispatch('ensureAvailableAppsLoaded');
      this.loadDocument();
      this.loadAddress();
      this.getPossibleUpgrades();
      if (this.$refs.myMap != null) this.$nextTick(() => {
        this.$refs.myMap.mapObject.ANY_LEAFLET_MAP_METHOD();
      });
    },
    clickRow(item, event) {
      if (event.isExpanded) {
        const index = this.interactionTableExpandedSync.findIndex(i => i === item);
        this.interactionTableExpandedSync.splice(index, 1);
      } else {
        this.interactionTableExpandedSync.push(item);
      }
    },
    openUpgradedDocument() {
      this.$router.replace({
        path: `/crm/addresses/${this.upgradedDocument.kdnr}/documents/${this.upgradedDocument.wfCid}`
      }).then(() => window.location.reload());
    },
    async loadDocument() {
      await axios.get(`/v1/crm/documents/${this.wf_cid}`).then(response => {
        this.document = response.data;

        //stop loading spinner on print
        this.document.printLoading = false;
        if (response.status != 200) {
          this.documentExists = 'false';
        } else {
          this.documentExists = 'true';
        }

        //document name
        this.document.name = `${this.document.shortType} ${this.document.nr}`;
        this.loadingDocument = false;
      }).catch(error => {
        console.error(error);
        this.documentExists = false;
      });
    },
    loadAddress() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response);
        if (response.status !== 200) {
          this.addressExists = 'false';
        } else {
          this.addressExists = 'true';
        }
        this.address = response.data;
        this.loading = false;
        this.addressTypeName = this.address.addressTypes;
      }).catch(error => {
        console.error(error);
        this.loading = false;
      });
    },
    addressTypesIcons(item) {
      return addressService.getAddressTypeDetailed(item).icon;
    },
    addressTypesNames(item) {
      return addressService.getAddressTypeDetailed(item).name;
    },
    printDocument() {
      documentService.printDocument(this.document);
    },
    formatCurrency(value) {
      return currencyFormatService.formatEuro(value);
    },
    formatPercentage(value) {
      return value * 100 + '%';
    },
    formatDate(value) {
      return dateTimeService.getShortGermanDateWithNumericYear(value);
    },
    upgradeOffer() {
      this.upgradeDialog = true;
    },
    async getPossibleUpgrades() {
      if (this.$store.getters.userHasAppById(8)) {
        await axios.get(`/v1/business/document/${this.wf_cid}/upgrades`).then(response => {
          if (response.status == 200) {
            this.possibleUpgrades = response.data;
          } else {
            this.failMessage = 'Beim Laden der möglichen Upgrades ist ein Fehler aufgetreten!';
            this.error = true;
          }
        });
      }
    },
    upgradeDocumentDialog(upgradeType) {
      this.fromUpgade = this.document.type;
      this.toUpgrade = upgradeType;
      this.upgradeDialog = true;
    },
    upgradeDocument(upgrade) {
      axios.patch(`/v1/business/document/${this.document.wf_cid}/upgrade`, upgrade).then(res => {
        if (res.status == 200) {
          this.upgradedDocument = res.data;
          this.SuccessSnackbar = true;
          this.getPossibleUpgrades();
        }
      });
      this.upgradeDialog = false;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.params': 'init'
  }
};