import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.documentExists ? _c('div', [_c(VLayout, [_c(VRow, {
    staticClass: "mt-3 mx-4"
  }, [_c(VCol, {
    staticClass: "pa-5 pt-10 px-10",
    style: _vm.fullscreenStyle,
    attrs: {
      "cols": "12",
      "xl": _vm.fullscreen ? 12 : 10,
      "lg": _vm.fullscreen ? 12 : 9,
      "md": "7",
      "sm": "12"
    }
  }, [_c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "900px"
    },
    model: {
      value: _vm.upgradeDialog,
      callback: function ($$v) {
        _vm.upgradeDialog = $$v;
      },
      expression: "upgradeDialog"
    }
  }, [_c(VCard, {
    staticClass: "px-5 py-3"
  }, [_c(VCardTitle, {
    staticClass: "headline justify-center"
  }, [_vm._v("Upgrade von " + _vm._s(_vm.fromUpgade) + " zu " + _vm._s(_vm.toUpgrade.bezeichnung) + " duchführen?")]), _c(VCardText), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary lighten-1 px-8"
    },
    on: {
      "click": function ($event) {
        return _vm.upgradeDocument(_vm.toUpgrade);
      }
    }
  }, [_vm._v("UPGRADE")]), _c(VBtn, {
    attrs: {
      "color": "error",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.upgradeDialog = false;
      }
    }
  }, [_vm._v("Abbrechen")])], 1)], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "4"
    }
  }, [_vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "image"
    }
  })] : _c(VCard, {
    staticClass: "border-left-primary-3 elevation-0 py-2 px-4 rounded-0 mx-auto",
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, [_c('router-link', {
    attrs: {
      "to": {
        name: 'crmAddress',
        params: {
          lfdnr: _vm.address.key.lfdnr
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.address.firstname == null && _vm.address.lastname == null ? _vm.address.name : `${_vm.address.firstname} ${_vm.address.lastname}`) + " ")])], 1), _c(VCardSubtitle, [_vm._v(" " + _vm._s(_vm.siteTitle) + " (" + _vm._s(_vm.document.state) + ") ")]), _c(VCardText, {
    staticClass: "pb-6"
  }, [_c(VRow, {
    staticClass: "mx-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" Straße: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.address.address.street) + " ")])], 1), _c(VRow, {
    staticClass: "mx-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" Ort: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.address.address.zip) + " " + _vm._s(_vm.address.address.city) + " ")])], 1), _c(VRow, {
    staticClass: "mx-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" Land: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm.address.address.country.lfdnr == 1 ? _c('span', [_vm._v(_vm._s('Austria') + " ")]) : _vm.address.address.country.lfdnr == 15 ? _c('span', [_vm._v(_vm._s('Germany') + " ")]) : _vm._e()])], 1)], 1), _c(VCardActions, [_vm.fullscreen ? _c('LBAButton', {
    attrs: {
      "buttonSize": 'small'
    },
    on: {
      "click": function ($event) {
        _vm.fullscreen = false;
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-eye")]), _vm._v(" Details einblenden")], 1) : _c('LBAButton', {
    attrs: {
      "buttonSize": 'small'
    },
    on: {
      "click": function ($event) {
        _vm.fullscreen = true;
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-eye-off")]), _vm._v(" Details ausblenden")], 1)], 1)], 1)], 2), _c(VCol, {
    staticClass: "mt-1"
  }, [_vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "text@6"
    }
  })] : _vm._e(), _c('td', {
    domProps: {
      "innerHTML": _vm._s(_vm.document.header)
    }
  })], 2)], 1), _c(VCard, {
    staticClass: "mx-auto my-2",
    attrs: {
      "elevation": "0"
    }
  }, [_c('template', {
    slot: "progress"
  }, [_c(VProgressLinear, {
    attrs: {
      "color": "primary",
      "height": "10",
      "indeterminate": ""
    }
  })], 1), _c(VRow, {
    staticClass: "mt-10 pb-5"
  }, [_c(VCol, [_c('h2', {
    staticClass: "ml-2 mt-3"
  }, [_vm._v("Positionen")])]), _c(VCol, [_c(VContainer, [_c(VTextField, {
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "label": "Suche",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c(VDataTable, {
    staticClass: "elevation-0",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.document.positions,
      "search": _vm.search,
      "loading": _vm.loading || _vm.loadingDocument,
      "loading-text": "Daten werden geladen...",
      "show-expand": "",
      "item-key": "id.pos",
      "single-expand": _vm.positionTableSingleExpand,
      "expanded": _vm.positionTableExpandedSync
    },
    on: {
      "update:expanded": function ($event) {
        _vm.positionTableExpandedSync = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c(VSkeletonLoader, {
          attrs: {
            "type": "table-row@5"
          }
        })];
      },
      proxy: true
    }, {
      key: "item.singlePriceNet",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.singlePriceNet)))])];
      }
    }, {
      key: "item.singlePriceGross",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.singlePriceGross)))])];
      }
    }, {
      key: "item.discount1",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatPercentage(item.discount1)))])];
      }
    }, {
      key: "item.discount2",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatPercentage(item.discount2)))])];
      }
    }, {
      key: "item.totalPriceNet",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.totalPriceNet)))])];
      }
    }, {
      key: "item.totalPriceGross",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.totalPriceGross)))])];
      }
    }, {
      key: "expanded-item",
      fn: function ({
        headers,
        item
      }) {
        return [item.posText != '' ? _c('td', {
          staticClass: "py-4",
          attrs: {
            "colspan": headers.length
          },
          domProps: {
            "innerHTML": _vm._s(item.posText)
          }
        }) : _c('td', {
          staticClass: "py-4",
          attrs: {
            "colspan": headers.length
          }
        }, [_vm._v(" Kein Positionstext vorhanden ")])];
      }
    }], null, false, 2297038663)
  }), _c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_c(VRow, {
    staticClass: "ml-2"
  }, [_c(VCol, [_c(VIcon, [_vm._v("mdi-truck-alert")]), _vm._v(" Lieferbedingung "), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "table-cell"
    }
  })] : _c(VTextField, {
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.document.deliveryCondition,
      callback: function ($$v) {
        _vm.$set(_vm.document, "deliveryCondition", $$v);
      },
      expression: "document.deliveryCondition"
    }
  }), _c(VIcon, [_vm._v("mdi-cash")]), _vm._v(" Zahlungsbedingung "), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "table-cell"
    }
  })] : _c(VTextField, {
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.document.paymentCondition,
      callback: function ($$v) {
        _vm.$set(_vm.document, "paymentCondition", $$v);
      },
      expression: "document.paymentCondition"
    }
  })], 2), _c(VCol, [_c(VIcon, [_vm._v("mdi-package-variant-closed")]), _vm._v(" Versandart "), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "table-cell"
    }
  })] : _c(VTextField, {
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.document.deliveryType,
      callback: function ($$v) {
        _vm.$set(_vm.document, "deliveryType", $$v);
      },
      expression: "document.deliveryType"
    }
  }), _c(VIcon, [_vm._v("mdi-cash")]), _vm._v(" Zahlungsart "), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "table-cell"
    }
  })] : _c(VTextField, {
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.document.paymentType,
      callback: function ($$v) {
        _vm.$set(_vm.document, "paymentType", $$v);
      },
      expression: "document.paymentType"
    }
  })], 2)], 1)], 1), _c(VSpacer), !_vm.loading && !_vm.loadingDocument ? _c(VCol, {
    staticClass: "mt-5 mr-3",
    attrs: {
      "cols": "4"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c('b', [_vm._v("Warenwert Netto: ")])]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.totalPriceNet)))])])], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v("Umsatzsteuer: ")]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.tax)))])], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c('b', [_vm._v("Warenwert Brutto: ")])]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.totalPriceGross)))])])], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v("Kopfrabatt: ")]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.formatPercentage(_vm.document.discount)))])], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    staticClass: "red--text",
    attrs: {
      "cols": "8"
    }
  }), _c(VCol, {
    staticClass: "text-right red--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.discountValue)))])], 1), _c(VDivider, {
    staticClass: "my-3"
  }), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c('b', [_vm._v("Zu zahlender Betrag: ")])]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.formatCurrency(_vm.document.totalPriceToPay)))])])], 1)], 1) : _vm._e()], 1)], 2), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    staticClass: "mt-6",
    attrs: {
      "type": "text@6"
    }
  })] : _c(VRow, {
    staticClass: "pl-3 my-5"
  }, [_c('td', {
    domProps: {
      "innerHTML": _vm._s(_vm.document.footer)
    }
  })]), _c(VSnackbar, {
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        attrs
      }) {
        return [_c(VBtn, _vm._b({
          attrs: {
            "color": "green darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openUpgradedDocument();
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" Dokument ansehen ")])];
      }
    }], null, false, 4218944648),
    model: {
      value: _vm.SuccessSnackbar,
      callback: function ($$v) {
        _vm.SuccessSnackbar = $$v;
      },
      expression: "SuccessSnackbar"
    }
  }, [_vm._v(" Upgrade erfolgreich durchgeführt! ")]), [_c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })]], 2), _c(VSpacer), !_vm.fullscreen ? _c(VCol, {
    staticStyle: {
      "max-width": "25%"
    },
    attrs: {
      "cols": "12",
      "xl": "3",
      "lg": "3",
      "md": "5",
      "sm": "12"
    }
  }, [_c(VNavigationDrawer, {
    staticClass: "overflow-y-auto py-5 px-8 pt-10",
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "width": "fit-content",
      "clipped": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
          staticClass: "mt-6 ml-n2",
          attrs: {
            "type": "list-item-two-line"
          }
        })] : [_c('h2', {
          staticClass: "mt-8"
        }, [_vm._v(_vm._s(_vm.siteTitle))]), _c('h5', {
          staticClass: "mt-1 grey--text"
        }, [_vm._v("Status: " + _vm._s(_vm.document.state))])], _c(VRow, {
          staticClass: "pt-4 pb-4 border-bottom-primary-3 mt-3"
        }, [_vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
          staticClass: "ml-2",
          attrs: {
            "type": "button"
          }
        })] : _c(VBtn, {
          staticClass: "mx-3",
          attrs: {
            "outlined": "",
            "loading": _vm.document.printLoading
          },
          on: {
            "click": _vm.printDocument
          }
        }, [_c(VIcon, [_vm._v("mdi-printer")]), _vm._v(" Drucken ")], 1)], 2)];
      },
      proxy: true
    }], null, false, 201495810)
  }, [_c('h3', {
    staticClass: "mt-10 mb-3"
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-file-tree")]), _vm._v(" Abhängigkeiten ")], 1), _vm.loading || _vm.loadingDocument ? [_c(VSkeletonLoader, {
    attrs: {
      "type": "list-item-three-line"
    }
  })] : _c(VTreeview, {
    staticClass: "row-pointer",
    attrs: {
      "items": _vm.document.documentTree,
      "hoverable": "",
      "dense": "",
      "open-all": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function ({
        item
      }) {
        return [item ? _c(VIcon, [_vm._v(" mdi-file-document-outline ")]) : _vm._e()];
      }
    }, {
      key: "label",
      fn: function ({
        item
      }) {
        return [_vm.document.wf_cid != item.wfcid ? _c('router-link', {
          attrs: {
            "to": {
              name: 'crmAddressDocumentDetailed',
              params: {
                lfdnr: item.lfdnr,
                wf_cid: item.wfcid
              }
            }
          }
        }, [_vm._v(_vm._s(item.name))]) : _c('span', [_vm._v(_vm._s(item.name))])];
      }
    }], null, false, 1372220007)
  }), _c('h3', {
    staticClass: "mt-10 mb-3"
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-forum")]), _vm._v(" Interaktionen ")], 1), _c('div', [_c(VTextField, {
    attrs: {
      "label": "Interaktionen durchsuchen",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "clear-icon": "mdi-close-circle-outline"
    },
    model: {
      value: _vm.interactionSearch,
      callback: function ($$v) {
        _vm.interactionSearch = $$v;
      },
      expression: "interactionSearch"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-0 overflow-y-auto row-pointer",
    staticStyle: {
      "height": "25vh"
    },
    attrs: {
      "headers": _vm.interactionHeaders,
      "items": _vm.document.interactions,
      "search": _vm.interactionSearch,
      "loading": _vm.loading || _vm.loadingDocument,
      "loading-text": "Daten werden geladen...",
      "hide-default-footer": "",
      "disable-sort": "",
      "item-key": "date",
      "dense": "",
      "expanded": _vm.interactionTableExpandedSync
    },
    on: {
      "update:expanded": function ($event) {
        _vm.interactionTableExpandedSync = $event;
      },
      "click:row": _vm.clickRow
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c(VSkeletonLoader, {
          attrs: {
            "type": "table-row@5"
          }
        })];
      },
      proxy: true
    }, {
      key: "item.date",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatDate(item.date)))])];
      }
    }, {
      key: "expanded-item",
      fn: function ({
        headers,
        item
      }) {
        return [_c('td', {
          staticClass: "py-4",
          attrs: {
            "colspan": headers.length
          }
        }, [_c(VSimpleTable, {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('td', [_vm._v("Erstelldatum")]), _c('td', [_vm._v(_vm._s(_vm.formatDate(item.aDat)))])]), _c('tr', [_c('td', [_vm._v("Zuletzt verändert")]), _c('td', [_vm._v(_vm._s(_vm.formatDate(item.mDat)))])]), _c('tr', [_c('td', [_vm._v("Betreff Code")]), _c('td', [_vm._v(_vm._s(item.subject.code))])]), _c('tr', [_c('td', [_vm._v("Betreff")]), _c('td', [_vm._v(_vm._s(item.subject.description))])]), _c('tr', [_c('td', [_vm._v("Erfassung")]), _c('td', [_vm._v(_vm._s(item.capture))])]), _c('tr', [_c('td', [_vm._v("Stichworte")]), _c('td', [_vm._v(_vm._s(item.keywords))])]), _c('tr', [_c('td', [_vm._v("Text")]), _c('td', {
                domProps: {
                  "innerHTML": _vm._s(item.text)
                }
              })])])];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }], null, false, 2079837437)
  })], 1)], 2)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), !_vm.documentExists ? _c(VContainer, [_c(VCard, {
    staticClass: "pa-5 mt-16",
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c(VLayout, {
    attrs: {
      "column": "",
      "align-center": "",
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c(VFlex, {
    staticClass: "text-center mb-4",
    attrs: {
      "xs12": ""
    }
  }, [_c('h3', [_vm._v(" Das gesuchte Dokument konnte nicht gefunden werden ")])]), _c(VFlex, {
    attrs: {
      "xs12": ""
    }
  }, [_c(VImg, {
    staticClass: "mx-auto my-auto",
    attrs: {
      "src": require("@/assets/svg/undraw_warning_re_eoyh.svg"),
      "max-width": "550px",
      "contain": ""
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };