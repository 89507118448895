import "core-js/modules/es.array.push.js";
import axios from 'axios';
//import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
//import InteractionsTable from '@/components/crm/addresses/InteractionsTable.vue'
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
//import documentService from '@/services/crm/documentService.js'
//import ErrorDialog from '@/components/core/ErrorDialog.vue'
import addressService from '@/services/crm/addressService.js';
import dateTimeService from '@/services/cal/dateTimeService.js';
export default {
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: true
    }
  },
  data: () => ({
    address: {
      key: {
        lfdnr: '',
        firma: ''
      },
      address: {
        street: '',
        zip: '',
        country: {
          firma: '',
          sprache: '',
          lfdnr: ''
        }
      }
    },
    document: {
      name: '',
      nr: '',
      date: '',
      type: '',
      shortType: '',
      state: '',
      wf_cid: '',
      printLoading: false
    },
    documentExists: true,
    loading: true,
    loadingDocument: true,
    search: null,
    interactionSearch: null,
    positionTableExpandedSync: [],
    positionTableSingleExpand: true,
    interactionTableSingleExpand: true,
    interactionTableExpandedSync: [],
    showDocumentTree: false,
    showInteractions: false,
    headers: [{
      text: 'Pos',
      value: 'id.pos'
    }, {
      text: 'Artikel Nr.',
      value: 'artNr'
    }, {
      text: 'SM Code',
      value: 'smCode'
    }],
    interactionHeaders: [{
      text: 'Datum',
      value: 'date'
    }, {
      text: 'Betreff',
      value: 'subject.description'
    }, {
      text: 'Stichworte',
      value: 'keywords'
    }]
  }),
  methods: {
    init() {
      this.documentExists = true;
      this.$store.dispatch('ensureAvailableAppsLoaded');
      this.loadDocument();
      this.loadAddress();
      //this.getPossibleUpgrades()

      if (this.$refs.myMap != null) this.$nextTick(() => {
        this.$refs.myMap.mapObject.ANY_LEAFLET_MAP_METHOD();
      });
    },
    async loadDocument() {
      await axios.get(`/v1/crm/documents/${this.wf_cid}`).then(response => {
        this.document = response.data;

        //stop loading spinner on print
        this.document.printLoading = false;
        if (response.status != 200) {
          this.documentExists = 'false';
        } else {
          this.documentExists = 'true';
        }

        //document name
        this.document.name = `${this.document.shortType} ${this.document.nr}`;
        this.loadingDocument = false;
      }).catch(error => {
        console.error(error);
        this.documentExists = false;
      });
    },
    loadAddress() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response);
        if (response.status !== 200) {
          this.addressExists = 'false';
        } else {
          this.addressExists = 'true';
        }
        this.address = response.data;
        this.loading = false;
        this.addressTypeName = this.address.addressTypes;
      }).catch(error => {
        console.error(error);
        this.loading = false;
      });
    },
    async getPossibleUpgrades() {
      if (this.$store.getters.userHasAppById(8)) {
        console.log('hello');
        await axios.get(`/v1/business/document/${this.wf_cid}/upgrades`).then(response => {
          console.log(response.data);
          if (response.status == 200) {
            this.possibleUpgrades = response.data;
          } else {
            this.failMessage = 'Beim Laden der möglichen Upgrades ist ein Fehler aufgetreten!';
            this.error = true;
          }
        });
      }
    },
    formatCurrency(value) {
      return currencyFormatService.formatEuro(value);
    },
    formatPercentage(value) {
      return value * 100 + '%';
    },
    formatDate(value) {
      return dateTimeService.getShortGermanDateWithNumericYear(value);
    },
    clickRow(item, event) {
      if (event.isExpanded) {
        const index = this.interactionTableExpandedSync.findIndex(i => i === item);
        this.interactionTableExpandedSync.splice(index, 1);
      } else {
        this.interactionTableExpandedSync.push(item);
      }
    }
  },
  mounted() {
    this.init();
  }
};